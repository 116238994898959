"use client";
import Link from "next/link";
import { useState } from "react";
import AdvertiseOnRodeyo from "../AdvertiseForm";
import Alert, { useAlertStore } from "../Alert";
import { useContactModalStore } from "../ContactForm";
import LogoAndSocialMedia from "./LogoAndSocialMedia";
import Newsletter from "./Newsletter";

const navigation = {
  solutions: [
    { name: "Launchpad", href: "/launchpad", target: "_self" },
    { name: "Mint", href: "/create", target: "_self" },
  ],
  support: [
    {
      name: "Pricing & Fees",
      href: "https://help.rodeyo.com/guides/fees",
    },
    { name: "Forum & Guides", href: "https://help.rodeyo.com" },
  ],
  company: [
    { name: "White Paper", href: "https://whitepaper.dcentral.me/" },
    {
      name: "Careers",
      href: "mailto:careers@rodeyo.com",
    },
  ],
  legal: [
    { name: "Privacy", href: "/policies/privacy" },
    { name: "Copyright", href: "/policies/copyright" },
    { name: "Terms", href: "/policies/terms" },
    { name: "Rewards", href: "/policies/rewards" },
  ],
  learn: [{ name: "NFT Meaning", href: "/learn/nft-meaning" }],
};

export default function Footer() {
  const { setOpenContact } = useContactModalStore();
  const [advertiseModal, setAdvertiseModal] = useState(false);

  const currentYear = new Date().getFullYear();

  return (
    <footer className="sm:ml-[80px]" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 pb-8 pt-16  border-t border-dark-default-1 sm:pt-24 lg:px-8 lg:pt-16 lg:mt-20">
        <div className="lg:grid lg:grid-cols-3 xl:gap-8">
          <div className="space-y-8 sm:ml-[24px]">
            <LogoAndSocialMedia />
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 lg:col-span-2 xl:mt-0 sm:ml-[100px]">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Solutions
                </h3>
                <div className="mt-6 space-y-4">
                  {navigation.solutions.map((item) => (
                    <div key={item.name}>
                      <Link
                        href={item.href}
                        target={item.target}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                        aria-label={item.name}
                      >
                        {item.name}
                      </Link>
                    </div>
                  ))}
                  <div
                    onClick={() => setAdvertiseModal(true)}
                    className="cursor-pointer text-sm leading-6 text-gray-300 hover:text-white"
                  >
                    Advertise
                  </div>
                </div>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Support
                </h3>
                <div className="mt-6 space-y-4">
                  {navigation.support.map((item) => (
                    <div key={item.name}>
                      <Link
                        href={item.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                        aria-label={item.name}
                      >
                        {item.name}
                      </Link>
                    </div>
                  ))}
                  {/* Desktop Contact */}
                  <div
                    onClick={() => setOpenContact(true)}
                    className="hidden lg:flex cursor-pointer text-sm leading-6 text-gray-300 hover:text-white"
                  >
                    Contact Us
                  </div>
                  {/* Mobile Contact */}
                  <Link
                    href="/contact"
                    className="lg:hidden cursor-pointer text-sm leading-6 text-gray-300 hover:text-white"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="md:grid md:grid-cols-3 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Company
                </h3>
                <div className="mt-6 space-y-4">
                  {navigation.company.map((item) => (
                    <div key={item.name}>
                      <Link
                        href={item.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                        aria-label={item.name}
                      >
                        {item.name}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Legal
                </h3>
                <div className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <div key={item.name}>
                      <a
                        href={item.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                        aria-label={item.name}
                      >
                        {item.name}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Learn
                </h3>
                <div className="mt-6 space-y-4">
                  {navigation.learn.map((item) => (
                    <div key={item.name}>
                      <a
                        href={item.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                        aria-label={item.name}
                      >
                        {item.name}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Newsletter />
        <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-sm text-center leading-5 text-light-gray">
            &copy; 2022 - {currentYear} Dcentral Inc. All rights reserved.
          </p>
        </div>
        <div className="pt-8 text-light-gray pb-4 text-sm text-center justify-center flex items-center gap-x-1 leading-5 ">
          Made with{" "}
          <img
            src="/cowboy-hat.png"
            alt="cowboy hat"
            className="w-10 mx-1 h-auto object-contain"
          />
          in the USA <span className="text-[28px]">🇺🇸</span>
        </div>
      </div>
      {advertiseModal && (
        <AdvertiseOnRodeyo open={advertiseModal} setOpen={setAdvertiseModal} />
      )}
    </footer>
  );
}
